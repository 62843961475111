.SiteHeader {
  border: none !important;
}
.SiteHeader .SearchBox.ui.input {
  color: #fff;
}
.SiteHeader .SearchBox.ui.input input {
  background: #333;
  color: #fff;
  border: 0;
}
.SiteHeader .SearchBox.ui.input input::selection {
  color: #fff;
}
.SiteHeader .toggle-button {
  display: none;
}
.SiteHeader .toggleable {
  display: flex;
  flex: 1;
}
.SiteHeader .madgrades-logo a {
  font-size: 18px;
}
.SiteHeader .menu .item.active {
  border-color: #c5050c !important;
}

@media only screen and (max-width: 767px) {
  .SiteHeader .madgrades-logo {
    text-align: center;
    justify-content: space-between;
    align-items: flex-start;
  }
  .SiteHeader .toggle-button {
    display: block;
    width: 40px;
  }
  .SiteHeader .menu .item.active {
    border-color: transparent !important;
  }
  .SiteHeader .toggleable {
    display: none;
  }
  .SiteHeader .toggled {
    display: block;
  }
}
