/* any global styles for every page go here */
@import url("App.css");
@import url("pages/Course.css");
@import url("pages/Search.css");
@import url("pages/Home.css");
@import url("pages/Explore.css");
@import url("containers/SiteHeader.css");
@import url("containers/SiteFooter.css");
@import url("components/SearchBox.css");
html {
  touch-action: manipulation;
}

.ui.horizontal.divider.divider-small {
  color: #888;
  font-size: 80%;
}

span {
  word-wrap: break-word;
}

/* Mobile */
@media only screen and (max-width: 767px) {
  [class*="mobile hidden"],
  [class*="tablet only"]:not(.mobile),
  [class*="computer only"]:not(.mobile),
  [class*="large screen only"]:not(.mobile),
  [class*="widescreen only"]:not(.mobile),
  [class*="or lower hidden"] {
    display: none !important;
  }
}
/* Tablet / iPad Portrait */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  [class*="mobile only"]:not(.tablet),
  [class*="tablet hidden"],
  [class*="computer only"]:not(.tablet),
  [class*="large screen only"]:not(.tablet),
  [class*="widescreen only"]:not(.tablet),
  [class*="or lower hidden"]:not(.mobile) {
    display: none !important;
  }
}
/* Computer / Desktop / iPad Landscape */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  [class*="mobile only"]:not(.computer),
  [class*="tablet only"]:not(.computer),
  [class*="computer hidden"],
  [class*="large screen only"]:not(.computer),
  [class*="widescreen only"]:not(.computer),
  [class*="or lower hidden"]:not(.tablet):not(.mobile) {
    display: none !important;
  }
}
/* Large Monitor */
@media only screen and (min-width: 1200px) and (max-width: 1919px) {
  [class*="mobile only"]:not([class*="large screen"]),
  [class*="tablet only"]:not([class*="large screen"]),
  [class*="computer only"]:not([class*="large screen"]),
  [class*="large screen hidden"],
  [class*="widescreen only"]:not([class*="large screen"]),
  [class*="or lower hidden"]:not(.computer):not(.tablet):not(.mobile) {
    display: none !important;
  }
}
/* Widescreen Monitor */
@media only screen and (min-width: 1920px) {
  [class*="mobile only"]:not([class*=widescreen]),
  [class*="tablet only"]:not([class*=widescreen]),
  [class*="computer only"]:not([class*=widescreen]),
  [class*="large screen only"]:not([class*=widescreen]),
  [class*="widescreen hidden"],
  [class*="widescreen or lower hidden"] {
    display: none !important;
  }
}
